@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*::-webkit-scrollbar {
	height: 6px;
	width: 7px;
	transition: all 0.3s ease-in-out;
}

*::-webkit-scrollbar-track {
	background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
	background: #0078b6;
	border-radius: 50px;
	-webkit-border-radius: 50px;
}

*::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:active {
	background: #52a0f2;
}

:root:active {
	scrollbar-color: #52a0f2 #f1f1f1 !important;
}

:root {
	height: 6px;
	scrollbar-color: #0078b6 #f1f1f1 !important;
	scrollbar-width: thin !important;
	--primary: #0078b6;
	--black: #000;
	--secondary: #52a0f2;
	--lite-gray: #f1f1f1;
	--white: #ffffff;
	--card-hover: rgba(219, 229, 239, 0.3);
	--red: 'red';
}

.create_address_modal {
	scrollbar-width: thin !important;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}
input,
select,
textarea {
	font-size: 16px !important;
}
label {
	font-size: 15px;
}
.thin-bar {
	scrollbar-width: thin;
}

.opacity-0 {
	transition: all 0.3s ease-in-out !important;
}

.opacity-1-trans {
	opacity: 1;
	transition: all 0.3s ease-in-out;
}

button:disabled {
	background: #ccc;
	cursor: no-drop;
}

button:disabled::after {
	background-color: transparent;
}

.active-db {
	background-color: #52a0f2;
	color: #fff;
}
.active-db:hover {
	background-color: #52a0f2 !important;
	color: #fff !important;
}

.db-links {
	transition: all 0.3s ease-in-out;
}

.db-tab:hover,
.db-links:hover {
	background-color: #dbe5ef;
	color: #000;
}

.db-tab::after,
.db-tab::before {
	width: 0;
	height: 0;
}

.subButton::after {
	width: 0%;
	height: 0%;
	-webkit-transition: none;
	-moz-transition: none;
	-ms-transition: none;
	-o-transition: none;
	transition: none;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Manrope', sans-serif;
}

body {
	position: relative;
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow-x: hidden !important;
	background-color: white;
	/* visibility: hidden; */
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	line-height: 1;
	width: 100%;
	height: 100%;
	font-family: 'Manrope', sans-serif !important;
	letter-spacing: 1px !important;
	z-index: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

::selection {
	background-color: #52a0f2;
	color: #ffffff;
}

.modal-bg {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100%;
	z-index: 99 !important;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

/* modal backdrop */
.hide-bd {
	visibility: hidden;
	opacity: 0;
}
.show-bd {
	visibility: visible !important;
	opacity: 1 !important;
}

.loader {
	margin-left: 9rem;
}

.search-col-products {
	scrollbar-width: thin !important;
	height: 73vh;
	overflow-y: scroll;
}
.searchError-content {
	display: none;
}
.flexing {
	display: flex;
}

@media all and (max-width: 768px) {
	.line {
		position: relative;
	}

	.line::after {
		content: '';
		position: absolute;
		top: 0;
		left: 5px;
		background-color: #5f9cd8;
		border-radius: 10px;
		width: 90%;
		height: 1px;
	}

	.loader {
		margin-left: 16rem;
	}

	.loader-margin {
		margin-left: -2.2rem;
	}
}

@media (max-width: 360px) {
	.loader-margin {
		margin-left: -1.5rem;
	}
}

.width {
	width: 100%;
	max-width: 1650px;
	padding-left: 2.5%;
	padding-right: 2.5%;
	margin-left: auto;
	margin-right: auto;
}

.sm-width {
	width: 100%;
	max-width: 1200px;
	padding-left: 2.5%;
	padding-right: 2.5%;
	margin-left: auto;
	margin-right: auto;
}

.lite-blue-bg-color {
	background-color: #dbe5ef;
}

.primary-blue-bg {
	background-color: #0078b6;
}

.primary-blue-order {
	background-color: #0078b6;
}

.dark-blue-bg {
	background-color: #52a0f2;
}

.dark-blue-color {
	color: #52a0f2;
}

.primary-blue-color {
	color: #0078b6;
}

.header-wrapper {
	height: 80px;
}

.top-bar {
	-moz-transition: transform 0.3s ease-in-out;
	-ms-transition: transform 0.3s ease-in-out;
	-o-transition: transform 0.3s ease-in-out;
	-webkit-transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
}

.hide-topbar {
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
}

.register-scroll {
	scrollbar-width: thin !important;
}

.logo img,
.loader-layer-2 img,
.loader-logo-wrapper {
	width: 200px !important;
	height: 80px !important;
}

.hamburger li {
	width: 25px;
	height: 2px;
	margin-bottom: 3px;
	background-color: #fff;
}

.hamburger li:last-of-type {
	margin-bottom: 0;
}

footer .link-wrapper a::after,
.nc-mega-menu-links-wrapper::after,
.all-instruments-links .link::after,
.nav-links-container-desk li::after,
.mega-menu-links-wrapper-desk::after {
	content: '';
	width: 100%;
	height: 2px;
	background-color: #52a0f2;
	position: absolute;
	bottom: -10px;
	left: 0;
	-moz-transform: scaleX(0);
	-ms-transform: scaleX(0);
	-webkit-transform: scaleX(0);
	-o-transform: scaleX(0);
	transform: scaleX(0);
	-moz-transition: transform 0.3s ease-in-out;
	-ms-transition: transform 0.3s ease-in-out;
	-o-transition: transform 0.3s ease-in-out;
	-webkit-transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
	transform-origin: right;
}

.active-nav a::after,
.active-nav span::after {
	content: '';
	position: absolute;
	bottom: -10px;
	left: 0;
	width: 100%;
	height: 2px;
	background-color: #52a0f2;
	-webkit-border-radius: 50px;
	border-radius: 50px;
	transition: all 0.3s ease-in-out;
}

.sale-notify {
	background-color: #c62132 !important;
	border-radius: 5px;
}

.sale-notify-product {
	background-color: #c62132 !important;
	border-radius: 5px;
}

.sale-notify-special {
	background-color: #c62132 !important;
	border-radius: 5px;
}

.all-navigations-container .nc-mega-menu-links-wrapper::after {
	bottom: -5px;
}

.input-bg,
.cart-container,
.sign-in-up-container {
	background-color: rgba(0, 0, 0, 0.7);
}

.nav-categories-wrapper {
	background-color: rgba(0, 0, 0, 0.7);
	min-height: 100vh;
	z-index: 100 !important;
}

.all-navigations-container .nav-categories-wrapper {
	top: 0;
}

footer .link-wrapper a:hover::after,
.all-navigations-container .nc-mega-menu-links-wrapper:hover::after,
.all-instruments-links .link:hover::after,
.nav-links-container-desk li:hover::after,
.mega-menu-links-wrapper-desk:hover::after {
	transform-origin: left;
	-webkit-transform: scaleX(1);
	-moz-transform: scaleX(1);
	-ms-transform: scaleX(1);
	-o-transform: scaleX(1);
	transform: scaleX(1);
}

.mega-menu-links-inner-wrapper,
.nav-categories-inner-wrapper,
.nav-link-wrapper-mob {
	width: 300px;
	max-width: 90vw;
	min-height: 100vh;
}

.mega-menu-links-inner-wrapper {
	min-height: auto;
}

.all-navigations-container .nav-categories-inner-wrapper {
	width: max-content;
}

.all-navigations-container .nc-mega-menu-links-wrapper {
	width: max-content;
}

.all-navigations-container .nav-categories-wrapper {
	background-color: #000;
}

.signin-user-link,
.mega-menu-link,
.nc-mega-menu-link,
.nc-mega-menu-links-container,
.nav-link-wrapper-mob a {
	-webkit-transition: background 0.2s ease-in-out;
	-moz-transition: background 0.2s ease-in-out;
	-ms-transition: background 0.2s ease-in-out;
	-o-transition: background 0.2s ease-in-out;
	transition: background 0.2s ease-in-out;
}

.signin-user-link:hover,
.mega-menu-link:hover,
.nc-mega-menu-link:hover,
.nc-mega-menu-links-container:hover,
.nav-link-wrapper-mob a:hover {
	background-color: #dbe5ef;
	-webkit-transition: background 0.2s ease-in-out;
	-moz-transition: background 0.2s ease-in-out;
	-ms-transition: background 0.2s ease-in-out;
	-o-transition: background 0.2s ease-in-out;
	transition: background 0.2s ease-in-out;
}

.all-navigations-container .nc-mega-menu-links-container:hover {
	background-color: transparent !important;
}

.login-icon-wrapper .sign-mega-menu-container {
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.login-icon-wrapper:hover .sign-mega-menu-container {
	visibility: visible;
	opacity: 1;
	top: 2.5rem;
}

.mega-menu-links-inner-wrapper {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.mega-menu-links-container:hover .mega-menu-links-inner-wrapper {
	opacity: 1;
	visibility: visible;
	top: 1.8rem;
}

/* nav-category-megamenu */

.all-navigations-container .nav-categories-inner-wrapper {
	-webkit-transform: translateX(0%);
	-moz-transform: translateX(0%);
	-ms-transform: translateX(0%);
	-o-transform: translateX(0%);
	transform: translateX(0%);
}

.all-navigations-container .mega-menu-links-inner-wrapper {
	-webkit-transform: translateX(50%);
	-moz-transform: translateX(50%);
	-ms-transform: translateX(50%);
	-o-transform: translateX(50%);
	transform: translateX(50%);
}

.all-navigations-container .mega-menu-links-container:hover .mega-menu-links-inner-wrapper {
	top: 0;
}

.nav-categories-inner-wrapper {
	width: 350px;
	min-height: 100vh;
}

.nc-open {
	-webkit-transform: translateX(0%) !important;
	-moz-transform: translateX(0%) !important;
	-ms-transform: translateX(0%) !important;
	-o-transform: translateX(0%) !important;
	transform: translateX(0%) !important;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.nc-mega-menu-links-outer-wrapper {
	width: 350px;
	min-height: 100%;
	max-height: 275px;
	-webkit-transform: translateX(105%);
	-moz-transform: translateX(105%);
	-ms-transform: translateX(105%);
	-o-transform: translateX(105%);
	transform: translateX(105%);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.all-navigations-container .nc-mega-menu-links-container:hover .nc-mega-menu-links-outer-wrapper {
	-webkit-transform: translateX(75%);
	-moz-transform: translateX(75%);
	-ms-transform: translateX(75%);
	-o-transform: translateX(75%);
	transform: translateX(75%);
}

.nc-mega-menu-links-container:hover .nc-mega-menu-links-outer-wrapper {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateX(100%);
	-moz-transform: translateX(100%);
	-ms-transform: translateX(100%);
	-o-transform: translateX(100%);
	transform: translateX(100%);
}

.mob-search-bar-close {
	margin-top: 10%;
}

/* sign-in-up container */
.sign-in-up-wrapper,
.cart-container-wrapper {
	width: 450px;
	max-width: 100vw;
}

.sign-in-up-wrapper {
	max-width: 90vw;
}

.sign-in-up-wrapper .logo {
	width: 150px;
	height: 40px;
}

.switch-wrapper {
	-webkit-transition: background 0.2s ease-in-out;
	-moz-transition: background 0.2s ease-in-out;
	-ms-transition: background 0.2s ease-in-out;
	-o-transition: background 0.2s ease-in-out;
	transition: background 0.2s ease-in-out;
}

.switch-circle {
	width: 26px;
	height: 26px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}

.circle-border {
	border: 1px solid #52a0f2;
}

.switch-toggle {
	-webkit-transform: translateX(75%);
	-moz-transform: translateX(75%);
	-ms-transform: translateX(75%);
	-o-transform: translateX(75%);
	transform: translateX(75%);
	-webkit-transition: transform 0.2s ease-in-out;
	-moz-transition: transform 0.2s ease-in-out;
	-ms-transition: transform 0.2s ease-in-out;
	-o-transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
}

.social-login-wrapper img {
	width: 28px;
	height: 28px;
}

.social-login-wrapper .linkedin img {
	width: 37px !important;
	height: 37px !important;
}

.social-login-wrapper a {
	-webkit-transition: transform 0.2s ease-in-out;
	-moz-transition: transform 0.2s ease-in-out;
	-ms-transition: transform 0.2s ease-in-out;
	-o-transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
}

.social-login-wrapper a:hover {
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	transform: scale(1.1);
}

select,
input:focus-visible,
input:focus {
	border-color: #52a0f2;
	outline: none;
}

main {
	position: relative;
	/* margin-top: 203px; */
}

.main-pad {
	margin-top: 163px !important;
}

.nc-top {
	top: 163px !important;
}

img,
iframe {
	object-fit: cover;
	object-position: center;
}

.featured-img {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 70%;
	height: 70%;
}

.featured-product::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(219, 229, 239, 0.3);
	z-index: 2;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	border-radius: 50%;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	transform-origin: center;
	-webkit-transition: transform 0.3s ease-in-out;
	-moz-transition: transform 0.3s ease-in-out;
	-ms-transition: transform 0.3s ease-in-out;
	-o-transition: transform 0.3s ease-in-out;
	transition: transform 0.3s ease-in-out;
}

.featured-product::after {
	z-index: 1;
	-webkit-transform: scaleY(0);
	-moz-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-ms-border-radius: 0;
	-o-border-radius: 0;
	border-radius: 0;
	transform-origin: bottom;
}

.featured-product:hover::after {
	-webkit-transform: scaleY(1);
	-moz-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
}

/* accordion */
.open-icon {
	top: 50%;
	right: 2.5%;
	transition: transform 0.3s ease-in-out;
	-webkit-transform: translate(-2.5%, -50%) rotate(0deg);
	-moz-transform: translate(-2.5%, -50%) rotate(0deg);
	-ms-transform: translate(-2.5%, -50%) rotate(0deg);
	-o-transform: translate(-2.5%, -50%) rotate(0deg);
	transform: translate(-2.5%, -50%) rotate(0deg);
}

.accordion-opener {
	cursor: pointer;
	width: max-content;
	transition: color 0.3s ease-in-out;
}

.text-hider {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.3s ease-in-out;
}

/* accordion ends here */

footer .logo {
	width: 200px;
	height: 80px;
}

.social-info img {
	width: 28px !important;
	object-fit: contain;
}

.social-info .linkedin img {
	width: 37px !important;
	/* margin-top: -3px !important; */
}

.social-info a {
	-webkit-transition: transform 0.2s ease-in-out;
	-moz-transition: transform 0.2s ease-in-out;
	-ms-transition: transform 0.2s ease-in-out;
	-o-transition: transform 0.2s ease-in-out;
	transition: transform 0.2s ease-in-out;
}

.social-info a:hover {
	-webkit-transform: scale(1.2);
	-moz-transform: scale(1.2);
	-ms-transform: scale(1.2);
	-o-transform: scale(1.2);
	transform: scale(1.2);
}

.hover-color {
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.hover-color:hover {
	color: #0078b6;
}
.social-info a > span > img {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	box-sizing: border-box;
	padding: 0;
	border: none;
	margin: auto;
	display: block;
	width: 0;
	height: 0;
	min-width: 100%;
	max-width: 100%;
	min-height: 100%;
	max-height: 100%;
}

/* swiper slider styling */

.swiper-slide-blank {
	display: none !important;
}
.swiper-button-prev,
.swiper-button-next {
	position: absolute !important;
	left: 0 !important;
	width: 30px !important;
	height: 30px !important;
	z-index: 2 !important;
	background-color: white !important;
	width: 40px !important;
	height: 40px !important;
	padding: 7.5px !important;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	border: 2px solid #ccc;
	cursor: pointer;
	align-items: unset !important;
	top: 50%;
	transform: translateY(-50%);
}
.swiper-button-next:after {
	content: '' !important;
	display: inline-block;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='%23000000'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-size: cover;
	z-index: 2 !important;
	background-color: white !important;
	width: 20px !important;
	height: 22px !important;
}
.swiper-button-prev:after {
	content: '' !important;
	display: inline-block;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"/></svg>');
	background-repeat: no-repeat;
	background-size: cover;
	transform: rotate(180deg);
	z-index: 2 !important;
	background-color: white !important;
	width: 20px !important;
	height: 22px !important;
}
.swiper-button-next {
	left: initial !important;
	right: 0 !important;
}
.mySwiper {
	padding-bottom: 35px !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
	bottom: var(--swiper-pagination-bottom, 8px);
	left: 0;
	width: 100%;
	position: absolute;
	text-align: center;
	transform: translateZ(0);
	z-index: 10;
}
.swiper-pagination-bullet {
	margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
	cursor: pointer;
	width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
	height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 8px));
	display: inline-block;
	border-radius: var(--swiper-pagination-bullet-border-radius, 50%);
	background: var(--swiper-pagination-bullet-inactive-color, #000);
	opacity: var(--swiper-pagination-bullet-inactive-opacity, 0.2);
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
	opacity: var(--swiper-pagination-bullet-opacity, 1);
	background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.custProductSwiper {
	padding-bottom: 7rem !important;
}
.custProductSwiper .swiper-button-prev,
.custProductSwiper .swiper-button-next {
	top: calc(50% - 4rem) !important;
}
/* category page */
.sku {
	width: 130px !important;
}

.category-product-detail::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #52a0f2;
	z-index: -1;
	-webkit-transform: scaleY(0);
	-moz-transform: scaleY(0);
	-ms-transform: scaleY(0);
	-o-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	transform-origin: bottom;
}

.category-product-detail:hover::after {
	-webkit-transform: scaleY(1);
	-moz-transform: scaleY(1);
	-ms-transform: scaleY(1);
	-o-transform: scaleY(1);
	transform: scaleY(1);
	background-color: #52a0f2;
}

.category-product-detail:hover {
	color: #fff;
}

.category-product-detail:hover .old-price {
	color: #fff;
}

.category-product-img {
	width: 200px;
	margin-left: -30%;
	margin-top: -10%;
	border: 1px solid #0078b6;
	-webkit-transform: scale(0.8);
	-moz-transform: scale(0.8);
	-ms-transform: scale(0.8);
	-o-transform: scale(0.8);
	transform: scale(0.8);
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	transform-origin: center;
}

/* max-width */
.max-width {
	display: flex;
	align-items: center;
	justify-content: center;
	max-width: 85px;
	max-height: 85px;
	min-width: 85px;
	min-height: 85px;
}

/* product page */

.enlarged-img {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.transition {
	transition: all 0.3s ease-in-out !important;
}

.body-height {
	height: 100vh;
	overflow: hidden;
	-webkit-backface-visibility: hidden;
}
.signoff {
	transform: translateX(-100%);
	transition: all 0.6s cubic-bezier(0.76, 0, 0.24, 1);
	background-color: rgba(0, 0, 0, 0.7);
}
.animation-signin {
	transform: translateX(0) !important;
}
.review-signin {
	opacity: 1 !important;
	visibility: visible !important;
}

.enlarged-img-container {
	background-color: rgba(0, 0, 0, 0.7);
}

.product-img-wrapper {
	width: 100%;
	height: auto;
	padding-top: 100%;
}

button {
	position: relative;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	z-index: 1;
	overflow: hidden;
}

button::after {
	content: '';
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #0078b6;
	-webkit-transform: scale(0);
	-moz-transform: scale(0);
	-ms-transform: scale(0);
	-o-transform: scale(0);
	transform: scale(0);
	z-index: -1;
	transform-origin: bottom;
	-webkit-border-radius: 50px;
	-moz-border-radius: 50px;
	-ms-border-radius: 50px;
	-o-border-radius: 50px;
	border-radius: 50px;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

button.bg-white:hover {
	color: #fff;
}

button.bg-white:hover svg {
	stroke: #fff;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

button:hover::after {
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

button.slick-arrow::after {
	height: 0;
}

.mySwiper {
	padding-bottom: 35px !important;
}

textarea {
	resize: none;
}

.related-products-carasoul {
	cursor: grab;
}

.dropdown:hover .dropdown-menu {
	display: block;
}
.hemostat-tbl table {
	max-width: 600px !important;
}
.hemostat-tbl table th {
	border: 1px solid black !important;
	font-weight: bold !important;
}
.hemostat-tbl table tbody tr td:first-child strong {
	font-weight: bold;
}
.hemostat-spc {
	display: none;
}
.product-img-gallary-wrapper .sm-img > span > img,
.product-paymentImg > span > img,
.sign-in-up-wrapper img.sign_logo,
.product-img-container .product-img-wrapper img.d-main-img,
.nav-wrapper .first-logo.tb-logo > span > img,
.related-product-wrapper a span img.related-product-img,
.paymentImgfooter > span > img {
	position: absolute;
	inset: 0px;
	box-sizing: border-box;
	padding: 0px;
	border: none;
	margin: auto;
	display: block;
	width: 0px;
	height: 0px;
	min-width: 100%;
	max-width: 100%;
	min-height: 100%;
	max-height: 100%;
}

/* cart-page */
button.update-cart-btn::after,
button.place-order-btn::after,
button.read-all-blogs-btn::after {
	background-color: #52a0f2;
}

.primary-blue-bg:hover::after {
	background-color: #52a0f2;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.react-stars {
	cursor: none !important;
}

/* Define styles for focused state */
.textarea-faq:focus,
.textarea-faq:focus-within,
.contact-txtarea:focus {
	border: 1px solid #52a0f2;
	/* Add your desired border style */
}

/* .cart-page-cart-detail-container, */
.order-review-detail-wrapper {
	height: max-content;
}

.header-container.fixed {
	opacity: 0;
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.header-reveal {
	opacity: 1 !important;
	-webkit-transform: translateY(0) !important;
	-moz-transform: translateY(0) !important;
	-ms-transform: translateY(0) !important;
	-o-transform: translateY(0) !important;
	transform: translateY(0) !important;
	border-bottom: 1px solid #ddd;
}

.alert-message {
	padding: 20px;
	background: #00b6a5b8;
	border-radius: 5px;
	position: fixed;
	top: 10rem;
	z-index: 222;
	right: 2rem;
	color: #fff;
}

.search-item-price span.old_price {
	color: #999999;
	text-decoration: line-through;
}

.paging.active {
	background: #52a0f2;
	border: 1px solid #52a0f2;
}
.paging-hover:hover {
	background: #52a0f2;
	color: white;
}
.white-hover:hover {
	color: #52a0f2;
}

.white-hover:hover::after {
	background-color: #ffffff;
	border: 1px solid #52a0f2;
}

.dark-blue-hover {
	border-color: #0078b6;
	color: #0078b6;
}

.dark-blue-hover:hover {
	color: #ffffff;
}

.dark-blue-hover:hover::after {
	background-color: #0078b6;
	border: 1px solid #0078b6;
}

.add-to-cart-pop-wrapper {
	transition: all 0.3s ease-in-out;
}

.enlarged-img-wrapper-scale {
	-webkit-transform: scale(1);
	transform: scale(0.8);
	-moz-transform: scale(1);
	-ms-transform: scale(1);
	-o-transform: scale(1);
	transform: scale(1);
}

.hr-dark {
	background-color: rgba(0, 0, 0, 0.15);
	height: 1px;
	border: none;
}

.social-login-wrapper img {
	width: 30px !important;
}

.left-hid-100 {
	left: -100vh;
}

.deliver-address-form,
.cart-container-wrapper,
.sign-in-up-wrapper {
	width: 450px;
	max-width: 85vw;
}

.delivery-address-detail-wrapper {
	background-color: rgba(0, 0, 0, 0.7);
	cursor: auto;
}

.nc-mega-menu-links-container:nth-last-of-type(-n + 5) .nc-mega-menu-links-outer-wrapper {
	top: initial;
	bottom: 0;
}

.nc-mega-menu-links-inner-wrapper {
	min-height: max-content;
	max-height: 275px;
}

/* close button */
.close_svg {
	transition: all 0.3s ease-in-out;
	transform: rotate(0);
}

.close_svg:hover {
	transform: rotate(90deg);
}

p .return-form-check-on-next {
	display: none !important;
}

.nav-bottom-bar-container {
	z-index: 50 !important;
}

.hide-menu {
	transform: translateY(10px);
	opacity: 0;
	visibility: hidden;
	z-index: -1;
	transition: all 0.3s ease-in-out;
}
.show-menu {
	transform: translateY(0);
	opacity: 1;
	visibility: visible;
	z-index: 3;
	transition: all 0.3s ease-in-out;
}
.hidden-scroller {
	overflow: hidden !important;
}
.match-selected-option {
	color: var(--lite-gray);
	font-size: 1rem;
}

/* font size */
.welcome-msg,
.featured-category-imgs-container .featured-detail,
.featured-category-wrapper .feature-product-detail-wrapper .product-description,
.featured-category-wrapper .featured-category .featured-detail,
.feature-product-detail-wrapper .product-description,
#about-section .about-wrapper p,
.faqs .accordion-container .accordion-wrapper .acc-opened p,
.accordion-wrapper .acc-opened p,
.footer-subscribe-wrapper p,
.footer-wrapper .company-detail,
.footer-about-links a,
.footer-information-links .link-wrapper a,
.footer-support-links .link-wrapper a,
.location-detail .company-detail,
.location-detail div,
.copy-right,
.downloads-imgs-container .download-img-detail,
.dashboard-page-container .table-heading-container .dash-board-tbl-nt,
.category-right-column .category-description p,
.category-right-column .category-description ul li,
/* .catogery-products-container .category-product-detail span, */
.family-dropdown-wrapper label,
.enlarge-img-wrapper p,
.product-page-center-col .product-description p,
.product-page-center-col .product-description,
.product-page-center-col .category-name span,
.product-page-center-col .notice p,
.product-page-returns-policy div,
.product-detail-reviews p,
.product-page-related-products-container .related-products-carasoul .related-product-wrapper .related-product-detail,
.input-search-reslut-container .search-item-description,
.input-search-reslut-container .search-item-sku,
.input-search-reslut-container .search-item-price,
.input-search-reslut-container label,
.input-search-reslut-container .search-catgry,
.input-search-reslut-container > div > a,
.breadcrumb a,
.breadcrumb span,
.blogs-wrapper .blog p,
.recent-blogs .recent-blog-wrapper .recent-blog .recent-blog-title,
.sign-in-wrapper span,
.sign-in-up-wrapper span,
.remember-forgot-wrapper a,
.forgot,
.contact-details-container p,
.contact-details-container .contact-location .location-detail,
.cart-itm-title span,
.summary-description-text span,
.coupn-main-text,
.ordered-product-title,
.ordered-product-price,
.checkout-sumry-subtotal,
.checkout-sumry-discount,
.checkout-sumry-fee,
.checkout-sumry-total,
.returning-customer,
.shipping-address-detail .shippingaddress-label,
.saved-address-details,
.basic-detail-wrapper .basic-detail-wrapper-id div,
.basic-detail-wrapper .basic-detail-wrapper-date div,
.basic-detail-wrapper .basic-detail-wrapper-status div,
.cart-detail-wrapper .cart-product-detail span,
.cart-total-wrapper .text15-size,
.shipping-detail-wrapper.text15-size,
.payment-detail-wrapper.text15-size,
.orderStatus-text15-size,
.paymment-detail-reciept .text15-size,
.paymentModal-text .text15-size,
.profile-container .text15-size {
	font-size: 15px !important;
}

@media only screen and (min-width: 649px) and (max-width: 1024px) {
	.category-product-container {
		margin-top: 0 !important;
	}
}

.D_pages_wraper {
	max-width: 1100px;
	margin: 0 auto;
}

@media all and (min-width: 1441px) {
	.featured-category-imgs-container {
		grid-template-columns: repeat(5, 1fr) !important;
	}
}

@media all and (min-width: 1025px) and (max-width: 1440px) {
	.equine-type-products {
		grid-template-columns: repeat(4, 1fr) !important;
	}
}

@media all and (min-width: 1280px) {
	.header-container.fixed,
	.header-container,
	.nav-links-container-desk,
	.cart-wrapper,
	.login-icon-container {
		display: block;
	}
}

@media all and (max-width: 1325px) {
	.nav-links-container-desk .mr-6 {
		margin-right: 1rem !important;
	}
}

.search-hidden {
	opacity: 0;
}

@media all and (max-width: 1279px) {
	.nav-bottom-bar-container,
	.tb-logo {
		display: block;
	}

	.tb-logo img {
		width: 150px !important;
		height: 60px !important;
	}

	.main-pad {
		margin-top: 92px !important;
	}

	.nc-top {
		top: 92px !important;
	}

	.active-bar-mobile {
		background-color: #dbe5ef;
	}

	.mega-menu-links-container .mega-menu-links-inner-wrapper {
		-webkit-transform: translateX(100%);
		-moz-transform: translateX(100%);
		-ms-transform: translateX(100%);
		-o-transform: translateX(100%);
		transform: translateX(100%);
	}

	.search-hidden {
		opacity: 1;
	}

	.mega-menu-links-container .mega-menu-links-inner-wrapper {
		-webkit-transform: translateX(100%);
		-moz-transform: translateX(100%);
		-ms-transform: translateX(100%);
		-o-transform: translateX(100%);
		transform: translateX(100%);
	}

	.nav-links-container-mob,
	.mob-search-bar-container {
		background-color: rgba(0, 0, 0, 0.7);
		width: 100% !important;
	}

	.mega-menu-links-container:hover .mega-menu-links-inner-wrapper {
		top: 0 !important;
	}

	.nav-links-container-mob,
	.mob-search-bar-container {
		background-color: rgba(0, 0, 0, 0.7);
	}

	.nav-link-wrapper-mob {
		margin: 0;
		min-height: 100vh;
		height: max-content;
	}

	.nl-mob {
		visibility: visible;
	}

	.category-product-img {
		width: 150px;
	}

	.cart-cart-total-container {
		flex-direction: column;
	}
}

@media all and (max-width: 576px) {
	.image span {
		width: 70px !important;
		height: 70px !important;
	}

	.category-product-detail .price.cat-price {
		justify-content: start;
	}

	.category-product-detail {
		background-color: transparent;
		transition: background-color 0.3s ease-in-out;
	}

	.category-product-detail:hover {
		background-color: #52a0f2;
		color: white;
	}

	.alertvisible {
		font-size: 14px;
		max-width: 96%;
		flex-wrap: nowrap;
		padding: 8px 16px;
	}
	.tbar-text-main {
		display: block !important;
	}
	.mega-menu-links-container .mega-menu-links-inner-wrapper {
		max-width: 65vw;
		-webkit-transform: translateX(40%);
		-moz-transform: translateX(40%);
		-ms-transform: translateX(40%);
		-o-transform: translateX(40%);
		transform: translateX(40%);
	}
}

@media all and (min-width: 769px) {
	.category-product-detail:hover .category-product-img {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
		visibility: visible;
		opacity: 1;
	}
}

@media all and (min-width: 641px) {
	.nc-icon-wrapper .n-c {
		display: block;
	}
}
@media all and (max-width: 646px) {
	.leading-snug {
		margin-top: 14px;
	}
	.breadcrum-up.leading-snug {
		margin-top: 0px !important;
	}
	.bread-leading.leading-snug {
		margin-top: 0px !important;
	}

	.custProductSwiper {
		padding-bottom: 3rem !important;
	}
	.custProductSwiper .swiper-button-prev,
	.custProductSwiper .swiper-button-next {
		top: calc(50% - 2rem) !important;
	}
	.nc-mega-menu-links-outer-wrapper {
		max-width: 250px;
	}
	.nc-mega-menu-links-container:hover .nc-mega-menu-links-outer-wrapper,
	.nc-mega-menu-links-container.active .nc-mega-menu-links-outer-wrapper {
		-webkit-transform: translateX(70%);
		-moz-transform: translateX(70%);
		-ms-transform: translateX(70%);
		-o-transform: translateX(70%);
		transform: translateX(70%);
	}
}

@media screen and (min-width: 480px) and (max-width: 638px) {
	.category-product-detail .price.cat-price {
		justify-content: end;
	}
}
@media screen and (min-width: 767px) and (max-width: 1050px) {
	.home-page .fc-home .featured-products-wrapper .product-description {
		min-height: 44px;
	}
}

@media all and (max-width: 425px) {
	.nc-top {
		top: 143px !important;
	}

	.logo img,
	.tb-logo img {
		width: 130px !important;
		height: 40px !important;
	}

	.switch-toggle {
		-webkit-transform: translateX(95%);
		-moz-transform: translateX(95%);
		-ms-transform: translateX(95%);
		-o-transform: translateX(95%);
		transform: translateX(95%);
	}

	.main-pad {
		margin-top: 72px !important;
	}

	.nc-top {
		top: 72px !important;
	}

	.nc-mega-menu-links-container {
		padding-left: 5%;
		padding-right: 2.5%;
	}

	.nc-mega-menu-links-outer-wrapper,
	.mega-menu-links-container .mega-menu-links-inner-wrapper {
		max-width: 65vw;
		-webkit-transform: translateX(40%);
		-moz-transform: translateX(40%);
		-ms-transform: translateX(40%);
		-o-transform: translateX(40%);
		transform: translateX(40%);
	}

	.nc-mega-menu-links-container:hover .nc-mega-menu-links-outer-wrapper,
	.nc-mega-menu-links-container.active .nc-mega-menu-links-outer-wrapper {
		-webkit-transform: translateX(35%);
		-moz-transform: translateX(35%);
		-ms-transform: translateX(35%);
		-o-transform: translateX(35%);
		transform: translateX(35%);
	}

	.nav-bottom-bar-wrapper .nav-icons {
		height: 60px;
		overflow-x: hidden !important;
	}

	.sku {
		width: 133px !important;
	}

	.product-img-wrapper {
		width: 90%;
		padding-top: 100%;
	}

	.nc-mega-menu-links-outer-wrapper {
		max-height: 250px;
	}
	iframe[title*='recaptcha'] {
		transform: scale(0.9);
		transform-origin: 0 0;
	}
}
@media screen and (max-width: 1279px) {
	.sign-in-up-wrapper {
		margin: auto !important;
	}
}
@media all and (max-height: 600px) and (orientation: landscape) {
	.sign-in-up-container {
		align-items: flex-start !important;
	}

	.delivery-address-detail-wrapper,
	.sign-in-up-container {
		align-items: flex-start;
	}

	.o-mt {
		margin-top: 0.5rem !important;
	}
}

@media (max-width: 767px) {
	.table-heading-wrapper,
	.db-detail-wrapper {
		min-width: 540px;
		overflow-y: hidden;
		overflow-x: scroll;
		scrollbar-width: thin !important;
	}

	.table-heading-wrapper-address,
	.db-detail-wrapper-address {
		min-width: 800px;
		overflow-y: hidden;
		overflow-x: scroll;
		scrollbar-width: thin !important;
	}
}

@media (min-width: 1025px) {
	.stickyy {
		position: sticky;
	}
}
